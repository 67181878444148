const Excel = require("exceljs");

export const CreateExcelStampPayroll = (data: any, id:any,name:any) => {
  const workbook = new Excel.Workbook();
  const ReporteCalculo = workbook.addWorksheet(`Timbrado nómina ${name}-${id}`);
  const width = 40;
  const header = {
     type: "pattern",
     pattern: "solid",
     fgColor: { argb: "002060" },
   };
   const headerFont = {
     name: "Arial",
     color: { argb: "FFFFFF" },
     family: 2,
     size: 11,
     bold: true,
   };

   ReporteCalculo.columns = [
     { header: "NOMBRE", key: "name", width },
     { header: "UUID", key: "UUID", width },
     { header: "CÓDIGO ERROR", key: "CodigoError", width },
     { header: "MENSAJE ERROR", key: "MensajeIncidencia", width },
   ];

  let letter = 65;
  ReporteCalculo.columns.forEach(() => {
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteCalculo.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    let name = item?.Colaborador.toUpperCase() || "";

    ReporteCalculo.addRow({
      name,
      UUID: item.UUID,
      CodigoError: item.CodigoError,
      MensajeIncidencia: item.MensajeIncidencia     
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `ReporteTimbrado-${name}-${id}.xlsx`
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

